import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import {StrapiSharpImage} from "../utils/types"
import Navbar from "../components/navbar"
import {GatsbyImage} from "gatsby-plugin-image"
import article, {Article} from "../templates/article"

interface Props {
  readonly data: PageQueryData
}

const SectionArticle: React.FC<SectionArticleList> = ({article, className = ""}) => {
  return (
    <div className="md:w-1/4 2xl:w-1/5 my-4 mx-4 shadow-lg flex flex-col">
      <div>
        <GatsbyImage
          alt="article header image"
          className=""
          image={article.thumbnail.localFile.childImageSharp.gatsbyImageData}
          backgroundColor={`#040e18`}
          style={{height: "250px"}}
        />
      </div>
      <div className="px-6 py-2 flex-grow">
        <h1 className="text-tercen-blue font-medium text-lg py-2">{article.title}</h1>
        <p className="">{article.summaryText}</p>
      </div>
      <div className="px-6 py-4 text-right align-bottom">
        <a
          href={"/" + article.slug}
          className="justify-items-end bg-tercen-green-base hover:bg-tercen-green-hover text-white py-2 px-4">
          Read more
        </a>
      </div>
    </div>
  )
}

const About: React.FC<Props> = ({data}) => {
  const siteTitle = data.site.siteMetadata.title,
    signUpURL = "https://tercen.com/_signup",
    googleSignUpURL =
      "https://accounts.google.com/o/oauth2/auth?client_id=653844972403-ve6tjq31861siedt2pqp1h6ilk5kgbvk.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Ftercen.com%2F%3Fauth.provider%3Dgoogle&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline"
  const page = data.strapiAbout
  return (
    <Layout title="The Biomedical Data Analysis Platform" keywords={[`tercen`, `data science platform`]}>
      <div className="pb-24">
        <header className="relative">
          {page.bannerImage && (
            <>
              <GatsbyImage
                alt="article header image"
                className=""
                image={page.bannerImage.localFile.childImageSharp.gatsbyImageData}
                backgroundColor={`#040e18`}
                style={{minHeight: "220px", maxHeight: "320px"}}
              />
              <div className={`text-black pt-8 md:pt-12 lg:pt-24 md:container absolute bottom-0 left-0`}>
                <div className="w-full py-4 px-3 text-center md:text-left xl:6/12 md:w-9/12 lg:w-7/12 md:py-8 md:px-16 bg-tercen-yellow">
                  <h1 className="font-black text-4xl md:text-5xl">Welcome To Tercen</h1>
                  <h3 className="text-lg pt-2 md:pt-6 md:text-xl " dangerouslySetInnerHTML={{__html: page.intro}} />
                </div>
              </div>
            </>
          )}
        </header>
        <main className="py-8 px-4 md:px-12">
          <section className="flex flex-col flex-wrap md:flex-row md:px-6">
            {data.articles &&
              data.articles.edges.map((article: {node: Article}, key: number) => {
                return <SectionArticle article={article.node} />
              })}
          </section>
        </main>
      </div>
    </Layout>
  )
}

interface ArticleTag {
  name: string
  slug: string
  articles: Array<Article>
}
interface AboutSection {
  active: boolean
  tag: Array<ArticleTag>
  title: string
}

interface About {
  bannerImage: StrapiSharpImage
  intro: string
  sections: Array<AboutSection>
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  strapiAbout: About
  articles: {
    edges: {
      node: Article
    }[]
  }
}

export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    strapiAbout {
      intro
      bannerImage {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 60, layout: FULL_WIDTH)
          }
        }
      }
      sections {
        title
        active
        tag {
          id
          articles {
            id
            title
            intro
            tagline
            slug
          }
        }
      }
    }
    articles: allStrapiArticle {
      edges {
        node {
          id
          title
          intro
          tagline
          slug
          summaryText
          thumbnail {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(height: 500, quality: 80, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
export default About
